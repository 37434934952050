import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, useField, useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-phone-input-2/lib/style.css";
import MaskedInput from "react-text-mask";
import "./call-me-back-form.css";

/* const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ */

const phoneNumberMask = [
    "8",
    " ",
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
const MyCheckbox = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" });
    return (
        <>
            <label>
                <input {...field} {...props} type="checkbox" />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="error text-danger mt-n2 mb-2">{meta.error}</div>
            ) : null}
        </>
    );
};
const formSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, 'Должно быть не менее 2 символов')
        .max(60, 'Должно быть не более 60 символов')
        .required("Обязательное поле"),
    acceptedTerms: Yup.boolean()
        .required("Обязательное поле")
        .oneOf([true], "Необходимо согласие на обработку"),
    /*  phone: Yup.string().phone('RU', true, 'Проверьте правильность номера').required("Обязательное поле"), */
    phone: Yup.string().required("Обязательное поле"),
    callTime: Yup.string()
        // specify the set of valid values for call time
        // @see http://bit.ly/yup-mixed-oneOf
        .oneOf(
            ["с 8 до 9", "с 9 до 10", "с 10 до 11", "с 11 до 12", "с 12 до 13", "с 13 до 14", "с 14 до 15", "с 15 до 16", "с 16 до 17", "с 17 до 18", "с 18 до 19", "с 19 до 20"]
        )
        .required("Обязательное поле")
});


export default () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /* Server State Handling */
    const [serverState, setServerState] = useState();
    const handleServerResponse = (ok, msg) => {
        setServerState({ ok, msg });
    };
    const handleOnSubmit = (values, actions) => {
        axios({
            method: "POST",
            url: "https://formspree.io/f/mrgdbllp", //https://formspree.io/f/mrgdbllp
            data: values
        })
            .then(response => {
                actions.setSubmitting(false);
                actions.resetForm();
                handleServerResponse(true, "Спасибо, мы скоро с Вами свяжемся!");
            })
            .catch(error => {
                actions.setSubmitting(false);
                handleServerResponse(false, error.response.data.error);
            });
    };


    return (
        <div>
            <Button className="btn btn-dark btn-order-imperia" onClick={handleShow}>
                Перезвоните мне
                    </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="mx-auto" style={{ padding: '1rem 1rem', }}>Обратный звонок</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ fullName: "", callTime: "", phone: "", acceptedTerms: false }}
                        onSubmit={handleOnSubmit}
                        validationSchema={formSchema}
                    >

                        {({ isSubmitting }) => (
                            <Form id="fs-frm" noValidate>
                                <label htmlFor="full-name" className="font-weight-bold mb-1">Как к вам обращаться</label>
                                <Field className="form-control d-block mb-3" id="full-name" name="fullName" type="text" />
                                <ErrorMessage name="fullName" className="errorMsg d-block mt-n3 text-danger mb-3" component="p" />
                                <label htmlFor="phone" className="font-weight-bold mb-1">Телефон</label>
                                <Field
                                    name="phone"
                                    render={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="phone"
                                            placeholder="8 (___) ___-____"
                                            type="text"
                                            className="form-control d-block mb-3"
                                        />
                                    )} />

                                <ErrorMessage name="phone" className="errorMsg d-block mt-n3 text-danger mb-3" component="p" />
                                <label htmlFor="call-time" className="font-weight-bold mb-1">Время для звонка</label>
                                <Field className="form-control d-block mb-3" as="select" id="call-time" name="callTime">
                                    <option value="">Выбрать время</option>
                                    <option value="с 8 до 9">с 8 до 9</option>
                                    <option value="с 9 до 10">с 9 до 10</option>
                                    <option value="с 10 до 11">с 10 до 11</option>
                                    <option value="с 11 до 12">с 11 до 12</option>
                                    <option value="с 12 до 13">с 12 до 13</option>
                                    <option value="с 13 до 14">с 13 до 14</option>
                                    <option value="с 14 до 15">с 14 до 15</option>
                                    <option value="с 15 до 16">с 15 до 16</option>
                                    <option value="с 16 до 17">с 16 до 17</option>
                                    <option value="с 17 до 18">с 17 до 18</option>
                                    <option value="с 18 до 19">с 18 до 19</option>
                                    <option value="с 19 до 20">с 19 до 20</option>
                                </Field>
                                <ErrorMessage name="callTime" className="errorMsg d-block mt-n3 text-danger mb-3" component="p" />
                                <MyCheckbox name="acceptedTerms" className="mr-1">
                                    Даю согласие на обработку персональных данных
                                        </MyCheckbox>
                                <button type="submit" className="btn btn-dark btn-order-imperia mt-2" disabled={isSubmitting}>
                                    Отправить
                                        </button>
                                {serverState && (
                                    <p className={!serverState.ok ? "errorMsg" : "text-success"}>
                                        {serverState.msg}
                                    </p>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    );
};
