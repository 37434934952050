import React, { Component, useState } from 'react';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
} from 'react-floating-button-menu';
import MdClose from '@material-ui/icons/Clear';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp, faTelegram, faVk } from "@fortawesome/free-brands-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faCommentDots } from "@fortawesome/free-regular-svg-icons"
import "./floating-contact-icons.scss"
import { navigate } from "gatsby"
import { window } from "browser-monads"

function makeCall() {
    window.location = "tel:+7-921-908-0881";
}
function goToWhatsApp() {
    window.location = "https://wa.me/79219080881";
}
function goToTelegram() {
    window.location = "https://t.me/IMPERIA_St_meb";
}
function goToVk() {
    window.location = "https://vk.com/club95884987";
}
export default class FloatingContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <FloatingMenu
                style={{ position: 'fixed', bottom: '15px', right: '15px' }}
                slideSpeed={500}
                direction="up"
                spacing={8}
                isOpen={this.state.isOpen}
                className="contact-items-container"
            >
                <MainButton
                    iconResting={<FontAwesomeIcon
                        icon={faCommentDots}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-2x"
                    />}
                    iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                    backgroundColor="black"
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    size={56}
                />
                <ChildButton
                    icon={<FontAwesomeIcon
                        icon={faPhone}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-2x"
                        onClick={makeCall}

                    />}

                    size={40}

                />
                <ChildButton
                    icon={<FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-2x"
                        onClick={goToWhatsApp}
                    />}

                    size={40}
                />
                <ChildButton
                    icon={<FontAwesomeIcon
                        icon={faTelegram}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-2x"
                        onClick={goToTelegram}
                    />}

                    size={40}
                />
                <ChildButton
                    icon={<FontAwesomeIcon
                        icon={faVk}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-2x"
                        onClick={goToVk}
                    />}

                    size={40}
                />
            </FloatingMenu>
        );
    }
}

